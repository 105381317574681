import React, { useEffect, useState } from "react";
import { css } from "@emotion/react";
import { Button, Card, Row, Col, Container } from "react-bootstrap";
import styles from "./warehouseDashboard.module.css";
import Bouncer from "react-spinners/BounceLoader";
import axios from "axios";

const Dashboard = () => {
  const [countvalue, setcount] = useState();
  let [loading, setLoading] = useState(true);
  useEffect(() => {
    axios
      .get(`${process.env.REACT_APP_BACKEND}/dashboard-details`)
      .then((data) => setcount(data.data))
      .catch((err) => console.log(err));
  }, []);
  const override = css`
    display: block;
    margin: 0 auto;
  `;

  return (
    <div>
      {countvalue ? (
        <div style={{marginLeft:"700px"}}>
        <Row >
          <Card
            style={{
              marginLeft: "30px",
              marginRight: "20px",
              width: "200px",
              textAlign: "center",
              marginTop: "20px",
            }}
          >
            <h3 style={{ marginTop: "20px" }}>Articles</h3>
            <p>{countvalue?.ArticlesDetails}</p>
          </Card>
          <Card
            style={{
              marginRight: "20px",
              width: "200px",
              textAlign: "center",
              marginTop: "20px",
            }}
          >
            <h3 style={{ marginTop: "20px" }}>JobDetails</h3>
            <p>{countvalue?.jobDetails}</p>
          </Card>
          <Card
            style={{
              marginRight: "20px",
              width: "200px",
              textAlign: "center",
              marginTop: "20px",
            }}
          >
            <h3 style={{ marginTop: "20px" }}>VideoDetails</h3>
            <p>{countvalue?.videoDetails}</p>
          </Card>
        </Row>
        </div>
      ) : (
        <div>
          <div className="sweet-loading">
            <Bouncer
              color="rgb(62, 85, 163)"
              loading={loading}
              css={override}
              size={100}
            />
          </div>
        </div>
      )}
    </div>
  );
};

export default Dashboard;
