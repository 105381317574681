import "./EditJobs.css";
import { Button, Card, Row, Col, Container } from "react-bootstrap";
import axios from "axios";
import React, { Fragment, useEffect, useState } from "react";
import { useParams, Link, useHistory } from "react-router-dom";
import moment from "moment";
import { CKEditor } from "@ckeditor/ckeditor5-react";
import ClassicEditor from "@ckeditor/ckeditor5-build-classic";
import S3FileUpload from "react-s3";
import { date } from "yup/lib/locale";
const AWS = require("aws-sdk");

const SetArticles = () => {
  const [Articles, setArticles] = useState([]);
  const [preview, setPreview] = useState();
  const [ArticleImageUrl, setArticleImageUrl] = useState("");
  const [text, setText] = useState("");
  let id = useParams();
  const history = useHistory();
  const onhandleChange = (event, editor) => {
    const data = editor.getData();
    setArticles({
      ...Articles,
      jobContent: data,
    });
  };
  useEffect( () => {
    const jobs=async ()=>{
      await axios
      .get(`${process.env.REACT_APP_BACKEND}/jobs/${id.id}`)
      .then((res) => {
        setArticles(res.data.data);
        setArticleImageUrl(res.data.data.urlToImage);
        setPreview(res.data.data.urlToImage);
        console.log(res.data.data);
      })
      .catch((err) => console.log(err));
    }
    jobs()
  }, []);
  const onDelete = () => {
    setPreview(null);
    const s3 = new AWS.S3({
      accessKeyId: config.accessKeyId,
      secretAccessKey: config.secretAccessKey,
      region: "us-west-1",
    });
    let params = {
      Bucket: "ecomdailynews",
      Key: `${ArticleImageUrl.key}`,
    };
    s3.deleteObject(params, function (err, data) {
      if (err) console.log(err, err.stack);
      // error
      else {
        setArticleImageUrl(null);
      } // deleted
    });
    return;
  };
  const config = {
    bucketName: "ecomdailynews",
    dirName: "Photos/articles",
    region: "us-west-1",
    accessKeyId: "AKIAS6RICWHRDKUP4M5K",
    secretAccessKey: "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",
  };
  const uploadToS3 = (e) => {

    let image = e.target.files[0];
    if (
      image.name.match(/\.(jpg|jpeg|png|gif)$/) &&
      image.size < 500000
    ) {
    let reader = new FileReader();
    reader.readAsDataURL(image);
    reader.onload = (event) => {
      setPreview(event.target.result); // spread into a new array to trigger rerender
    };
    S3FileUpload.uploadFile(image, config)
      .then((data) => {
        setArticleImageUrl(data.location);
      })
      .catch((err) => {
        alert("Please re-upload the Image");
      });}
      else{
        alert(`Please select valid format and Size`);
      }
  };
  const onHandlechange = (event) => {
    if (event.target.name === "tags") {
      setArticles({
        ...Articles,
        [event.target.name]: event.target.value
          ? event.target.value.split(",")
          : event.target.value,
      });
    } else {
      setArticles({
        ...Articles,
        [event.target.name]: event.target.value,
      });
    }
  };

  const Editjobs = async () => {
    await axios
      .put(`${process.env.REACT_APP_BACKEND}/jobs/${id.id}`, {
        jobTitle: Articles.jobTitle,
        jobDescription: Articles.jobDescription,
        jobContent: Articles.jobContent,
        mentionedSalary:Articles.mentionedSalary,
        jobPlace:Articles.jobPlace,
        jobType:Articles.jobType,
        postedBy: Articles.postedBy,
        company:Articles.company,
        redirectUrl:Articles.redirectUrl,
        status:Articles.status,
        urlToImage: ArticleImageUrl,
      },{
        headers: {
          authtoken: `${localStorage.getItem("authtoken")}`,
        },
      })
      .then((res) => history.push("/jobs"))
      .catch((err) => console.log(err));
  };

  return (
    <div className="bodycolor">
      <div className="center" style={{ marginTop: "10px" }}>
        {Articles ? (
          <Card>
            <Row>
              <Col lg={1} sm={1} ></Col>
              <Col>
                <h3 className="heading">
                  <strong>Edit Jobs</strong>
                </h3>
                <div className="inputfields">
                  <div>
                    <label>Job Title :</label>
                  </div>
                  <input
                    autoComplete="off"
                    className="inputvalues"
                    defaultValue={Articles.title}
                    name="jobTitle"
                    value={Articles.jobTitle}
                    onChange={onHandlechange}
                    placeholder="Enter The name"
                  ></input>
                </div>
                <div className="inputfields">
                  <div>
                    <label>Job Description :</label>
                  </div>
                  <input
                    autoComplete="off"
                    onChange={onHandlechange}
                    name="jobDescription"
                    value={Articles.jobDescription}
                    className="inputvalues"
                    placeholder="Enter The Description"
                  ></input>
                </div>
                <div className="inputfields">
                  <div>
                    <label>Job Content :</label>
                  </div>
                  <div
                    style={{
                      backgroundColor: "rgb(240 239 239)",
                      width: "530px",
                    }}
                  >
                    <CKEditor
                      data={Articles.jobContent}
                      editor={ClassicEditor}
                      onChange={onhandleChange}
                      onInit={(editor) => {
                        console.log("Editor is ready to use!", editor);
                      }}
                      config={{
                        ckfinder: {
                          // ${process.env.REACT_APP_BACKEND}
                          uploadUrl: `${process.env.REACT_APP_BACKEND}/articles/upload`,
                        },
                      }}
                    />
                  </div>
                </div>
                <div className="inputfields">
                  <div>
                    <label>Header Image</label>
                  </div>
                  <input
                    autoComplete="off"
                    onChange={uploadToS3}
                    name="urlToImage"
                    className="Editimage"
                    type="file"
                  ></input>
                  {!preview && <span>     <span>Note: Image  less than 500kb and extension ".jpg .jpeg .png"</span></span>}
                </div>

                <div style={{ marginLeft: "60px", marginTop: "20px" }}>
                  {preview && (
                    <div>
                      <img
                        src={preview}
                        alt="..."
                        style={{ height: "100px", width: "200px" }}
                      />
                      <button
                        type="button"
                        onClick={onDelete}
                        className="close"
                        aria-label="Close"
                      >
                        <span
                          style={{ marginLeft: "-570px", marginTop: "-70px" }}
                          aria-hidden="true"
                        >
                          &times;
                        </span>
                      </button>
                    </div>
                  )}
                </div>
                <div className="inputfields">
                <div>
                  <label>Redirect URL :</label>
                </div>
                <input
                value={Articles.redirectUrl}
                  autoComplete="off"
                  onChange={onHandlechange}
                  name="redirectUrl"
                  className="inputvalues"
                  placeholder="Example:- Business, Technology, Sports etc"
                ></input>
              </div>
              <div className="inputfields">
                <div>
                  <label>Company Name :</label>
                </div>
                <input
                value={Articles.company}
                  autoComplete="off"
                  onChange={onHandlechange}
                  name="company"
                  className="inputvalues"
                  placeholder="Example:- Business, Technology, Sports etc"
                ></input>
              </div>
              <div className="inputfields">
                <Row>
                  <Col lg={6} md={6} sm={6}>
                    <div >
                      <label>Job Type :</label>
                    </div>
                    <select name="jobType" value={Articles.jobtype} style={{padding: "10px",border: "0px solid"}} onChange={onHandlechange}>
                      <option  value="Full-Time">Full-Time</option>
                      <option  value="Part-Time">Part-Time</option>
                      <option  value="Not-Mentioned">Not-Mentioned</option>
                    </select>
                  </Col>
                  <Col lg={6} md={6} sm={6}>
                    <div >
                      <label>Status :</label>
                    </div>
                    <select name="status" value={Articles.status} style={{padding: "10px 35px",border: "0px solid"}} onChange={onHandlechange}>
                      <option value="Active">Active</option>
                      <option value="InActive">InActive</option>
                      <option value="Expired">Expired</option>
                    </select>
                  </Col>
                  
                </Row>
              </div>
              <div className="inputfields">
                <Row>
                <Col lg={6} md={6} sm={6}>
                    <div>
                      <label className="datelabel">Job Place :</label>
                    </div>
                    <input
                      autoComplete="off"
                      value={Articles.postedBy}
                      onChange={onHandlechange}
                      name="postedBy"
                      className="typevalues"
                      placeholder="Enter The name"
                    ></input>
                  </Col>
                  <Col lg={6} md={6} sm={6}>
                    <div>
                      <label className="datelabel">Job Place :</label>
                    </div>
                    <input
                      autoComplete="off"
                      value={Articles.jobPlace}
                      onChange={onHandlechange}
                      name="jobPlace"
                      className="typevalues"
                      placeholder="Enter The name"
                    ></input>
                  </Col>
                  </Row>
                  </div>
              <div className="inputfields">
                <Row>
                  <Col lg={6} md={6} sm={6}>
                    <div style={{display:"flex"}}>
                      <label className="datelabel" >Salary :</label><span><p>&nbsp;Ex:$1000 - 3000</p></span>
                    </div>
                    <input
                    value={Articles.mentionedSalary}
                      autoComplete="off"
                      onChange={onHandlechange}
                      name="mentionedSalary"
                      className="typevalues"
                      placeholder="Salary Value"
                    ></input>
                  </Col>
                </Row>
              </div>

                <div className="inputfields buttonadjust">
                  <button className="button" onClick={Editjobs}>
                    <strong>Save</strong>
                  </button>
                  <div className="backbutton">
                    {" "}
                    <Link to="/articles">
                      <button className="button">
                        <strong>
                          <span>
                            <i class="fas fa-arrow-left"></i>
                          </span>{" "}
                          &nbsp;Back
                        </strong>
                      </button>
                    </Link>
                  </div>
                </div>
              </Col>
            </Row>
          </Card>
        ) : null}
      </div>
    </div>
  );
};
export default SetArticles;
