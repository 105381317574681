import "./SetArticles.css";
import { Card, Row, Col } from "react-bootstrap";
import React, { useState } from "react";
import ClassicEditor from "@ckeditor/ckeditor5-build-classic";
import { CKEditor } from "@ckeditor/ckeditor5-react";
import { useHistory, Link } from "react-router-dom";
import axios from "axios";
import S3FileUpload from "react-s3";

const SetArticles = () => {
  const initial_state = {
    title: "",
    description: "",
    tags: [],
    type: "",
    author: "",
    PublishedOn: "",
    imageUrl: "",
  };
  const [Articles, setArticles] = useState(initial_state);
  const [ArticleImageUrl, setArticleImageUrl] = useState("");
  const history = useHistory();

  const config = {
    bucketName: "ecomdailynews",
    dirName: "Photos/articles",
    region: "us-west-1",
    accessKeyId: "AKIAS6RICWHRDKUP4M5K",
    secretAccessKey: "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",
  };
  const onHandlechange = (event) => {
    event.preventDefault();
    if (event.target.name === "tags") {
      setArticles({
        ...Articles,
        [event.target.name]: [event.target.value],
      });
    } else {
      setArticles({
        ...Articles,
        [event.target.name]: event.target.value,
      });
    }
  };
  //replace(/\s/g, "");
  const uploadToS3 = (e) => {
    if (
      e.target.files[0].name.match(/\.(jpg|jpeg|png|gif)$/) &&
      e.target.files[0].size < 500000
    ) {
    S3FileUpload.uploadFile(e.target.files[0], config)
      .then((data) => {
        setArticleImageUrl(data.location);
      })
      .catch((err) => {
        alert("Please re-upload the Image");
      });}
      else{
        alert(`Please select valid format and size`);
      }
  };

  const post = async () => {
    let obj = {
      title: Articles.title,
      description: Articles.description,
      tags: Articles.tags,
      type: Articles.type,
      content: Articles.content,
      author: Articles.author,
      PublishedOn: parseInt(new Date(Articles.PublishedOn).valueOf()),
      imageUrl: ArticleImageUrl}
  await axios
  .post(`${process.env.REACT_APP_BACKEND}/articles`, obj,{
    headers: {
      authtoken: `${localStorage.getItem("authtoken")}`,
    },
  })
  .then((res) => history.push("/articles"))
  .catch((err) => console.log(err));
};
  const onhandleChange = (event, editor) => {
    const data = editor.getData();
    console.log(data)
    setArticles({
      ...Articles,
      content: data,
    });
  };

  return (
    <div className="bodycolor">
      <div
        className="center"
        style={{ marginTop: "10px" }}
        enctype="multipart/form-data"
      >
        <Card autocomplete="off">
          <Row>
            <Col lg={1} sm={1}></Col>
            <Col>
              <h3 className="heading">
                <strong>Create New Articles</strong>
              </h3>
              <div className="inputfields">
                <div>
                  <label>Title :</label>
                </div>
                <input
                  autoComplete="off"
                  className="inputvalues"
                  name="title"
                  onChange={onHandlechange}
                  placeholder="Enter The name"
                ></input>
              </div>
              <div className="inputfields">
                <div>
                  <label>Description :</label>
                </div>
                <input
                  autoComplete="off"
                  onChange={onHandlechange}
                  name="description"
                  className="inputvalues"
                  placeholder="Enter The Description"
                ></input>
              </div>
              <div className="inputfields">
                <div>
                  <label>Content :</label>
                </div>
                <div
                  style={{
                    backgroundColor: "rgb(240 239 239)",
                    width: "530px",
                  }}
                >
                  <CKEditor
                    editor={ClassicEditor}
                    onChange={onhandleChange}
                    onInit={(editor) => {
                      console.log("Editor is ready to use!", editor);
                    }}
                    config={{
                      ckfinder: {
                        // ${process.env.REACT_APP_BACKEND}
                        uploadUrl: `${process.env.REACT_APP_BACKEND}/articles/upload`,
                      },
                    }}
                  />
                </div>
              </div>
              <div>
                <label className="datelabel">Header Image</label> 
              </div>
              <input
                autoComplete="off"
                onChange={uploadToS3}
                name="imageUrl"
                className="datevalues2"
                type="file"
              ></input>
              <span>Note: Image  less than 500kb and extension ".jpg .jpeg .png"</span>
              <div className="inputfields">
                <div>
                  <label>Tags :</label>
                </div>
                <input
                  autoComplete="off"
                  onChange={onHandlechange}
                  name="tags"
                  className="inputvalues"
                  placeholder="Example:- Business, Technology, Sports etc"
                ></input>
              </div>
              <div className="inputfields">
                <Row>
                  <Col lg={6} md={6} sm={6}>
                    <div>
                      <label>Type :</label>
                    </div>
                    <input
                      autoComplete="off"
                      onChange={onHandlechange}
                      name="type"
                      className="datevalues"
                      placeholder="Enter The name"
                    ></input>
                  </Col>
                  <Col lg={6} md={6} sm={6}>
                    <div>
                      <label className="datelabel">Author :</label>
                    </div>
                    <input
                      autoComplete="off"
                      onChange={onHandlechange}
                      name="author"
                      className="datevalues2"
                      placeholder="Enter The name"
                    ></input>
                  </Col>
                </Row>
              </div>
              <div className="inputfields">
                <Row>
                  <Col lg={6} md={6} sm={6}>
                    <div>
                      <label className="datelabel">Published On :</label>
                    </div>
                    <input
                      autoComplete="off"
                      onChange={onHandlechange}
                      name="PublishedOn"
                      className="datevalues2"
                      placeholder="Enter The name"
                      type="date"
                    ></input>
                  </Col>
                </Row>
              </div>
              <div className="inputfields buttonadjust">
                <button className="button" onClick={post}>
                  <strong>Save</strong>
                </button>
                <div className="backbutton">
                  {" "}
                  <Link to="/articles">
                    <button className="button">
                      <strong>
                        <span>
                          <i class="fas fa-arrow-left"></i>
                        </span>{" "}
                        &nbsp;Back
                      </strong>
                    </button>
                  </Link>
                </div>
              </div>
            </Col>
          </Row>
        </Card>
      </div>
    </div>
  );
};
export default SetArticles;
