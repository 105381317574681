import "./EditArticles.css";
import { Button, Card, Row, Col, Container } from "react-bootstrap";
import axios from "axios";
import React, { Fragment, useEffect, useState } from "react";
import { useParams, Link, useHistory } from "react-router-dom";
import moment from "moment";
import { CKEditor } from "@ckeditor/ckeditor5-react";
import ClassicEditor from "@ckeditor/ckeditor5-build-classic";
import S3FileUpload from "react-s3";
import { date } from "yup/lib/locale";
const AWS = require("aws-sdk");

const SetArticles = () => {
  const [Articles, setArticles] = useState([]);
  const [preview, setPreview] = useState();
  const [ArticleImageUrl, setArticleImageUrl] = useState("");
  const [text, setText] = useState("");
  let id = useParams();
  const history = useHistory();
  const onhandleChange = (event, editor) => {
    const data = editor.getData();
    setArticles({
      ...Articles,
      content: data,
    });
  };
  useEffect(async () => {
    await axios
      .get(`${process.env.REACT_APP_BACKEND}/articles/${id.id}`)
      .then((res) => {
        setArticles(res.data.data);
        setArticleImageUrl(res.data.data.imageUrl);
        setPreview(res.data.data.imageUrl);
      })
      .catch((err) => console.log(err));
  }, []);
  const onDelete = () => {
    setPreview(null);
    const s3 = new AWS.S3({
      accessKeyId: config.accessKeyId,
      secretAccessKey: config.secretAccessKey,
      region: "us-west-1",
    });
    let params = {
      Bucket: "ecomdailynews",
      Key: `${ArticleImageUrl.key}`,
    };
    s3.deleteObject(params, function (err, data) {
      if (err) console.log(err, err.stack);
      // error
      else {
        setArticleImageUrl(null);
      } // deleted
    });
    return;
  };
  const config = {
    bucketName: "ecomdailynews",
    dirName: "Photos/articles",
    region: "us-west-1",
    accessKeyId: "AKIAS6RICWHRDKUP4M5K",
    secretAccessKey: "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",
  };
  const uploadToS3 = (e) => {

    let image = e.target.files[0];
    if (
      image.name.match(/\.(jpg|jpeg|png|gif)$/) &&
      image.size < 500000
    ) {
    let reader = new FileReader();
    reader.readAsDataURL(image);
    reader.onload = (event) => {
      setPreview(event.target.result); // spread into a new array to trigger rerender
    };
    S3FileUpload.uploadFile(image, config)
      .then((data) => {
        setArticleImageUrl(data.location);
      })
      .catch((err) => {
        alert("Please re-upload the Image");
      });}
      else{
        alert(`Please select valid format and Size`);
      }
  };
  const onHandlechange = (event) => {
    if (event.target.name === "tags") {
      setArticles({
        ...Articles,
        [event.target.name]: event.target.value
          ? event.target.value.split(",")
          : event.target.value,
      });
    } else {
      setArticles({
        ...Articles,
        [event.target.name]: event.target.value,
      });
    }
  };

  const PUT = async () => {

    await axios
      .put(`${process.env.REACT_APP_BACKEND}/articles/${id.id}`, {
        author: Articles.author,
        description: Articles.description,
        tags: Articles.tags,
        content: Articles.content,
        title: Articles.title,
        type: Articles.type,
        imageUrl: ArticleImageUrl,
      },{
        headers: {
          authtoken: `${localStorage.getItem("authtoken")}`,
        },
      })
      .then((res) => history.push("/articles"))
      .catch((err) => console.log(err));
  };

  return (
    <div className="bodycolor">
      <div className="center" style={{ marginTop: "10px" }}>
        {Articles.tags ? (
          <Card>
            <Row>
              <Col lg={1} sm={1} sm={1}></Col>
              <Col>
                <h3 className="heading">
                  <strong>Edit Articles</strong>
                </h3>
                <div className="inputfields">
                  <div>
                    <label>Title :</label>
                  </div>
                  <input
                    autoComplete="off"
                    className="inputvalues"
                    defaultValue={Articles.title}
                    name="title"
                    value={Articles.title}
                    onChange={onHandlechange}
                    placeholder="Enter The name"
                  ></input>
                </div>
                <div className="inputfields">
                  <div>
                    <label>Description :</label>
                  </div>
                  <input
                    autoComplete="off"
                    onChange={onHandlechange}
                    name="description"
                    value={Articles.description}
                    className="inputvalues"
                    placeholder="Enter The Description"
                  ></input>
                </div>
                <div className="inputfields">
                  <div>
                    <label>Content :</label>
                  </div>
                  <div
                    style={{
                      backgroundColor: "rgb(240 239 239)",
                      width: "530px",
                    }}
                  >
                    <CKEditor
                      data={Articles.content}
                      editor={ClassicEditor}
                      onChange={onhandleChange}
                      onInit={(editor) => {
                    
                      }}
                      config={{
                        ckfinder: {
                          // ${process.env.REACT_APP_BACKEND}
                          uploadUrl: `${process.env.REACT_APP_BACKEND}/articles/upload`,
                        },
                      }}
                    />
                  </div>
                </div>
                <div className="inputfields">
                  <div>
                    <label>Header Image</label>
                  </div>
                  <input
                    autoComplete="off"
                    onChange={uploadToS3}
                    name="imageUrl"
                    className="Editimage"
                    type="file"
                  ></input>
                  {!preview && <span>     <span>Note: Image  less than 500kb and extension ".jpg .jpeg .png"</span></span>}
                </div>
                <div style={{ marginLeft: "60px", marginTop: "20px" }}>
                  {preview && (
                    <div>
                      <img
                        src={preview}
                        alt="..."
                        style={{ height: "100px", width: "200px" }}
                      />
                      <button
                        type="button"
                        onClick={onDelete}
                        className="close"
                        aria-label="Close"
                      >
                        <span
                          style={{ marginLeft: "-570px", marginTop: "-70px" }}
                          aria-hidden="true"
                        >
                          &times;
                        </span>
                      </button>
                    </div>
                  )}
                </div>
                <div className="inputfields">
                  <div>
                    <label>Tags :</label>
                  </div>
                  <input
                    autoComplete="off"
                    onChange={onHandlechange}
                    defaultValue={Articles.tags.map((val) => val)}
                    name="tags"
                    className="inputvalues"
                    placeholder="Enter The Tags"
                  ></input>
                </div>
                <div className="inputfields">
                  <Row>
                    <Col lg={6} md={6} sm={6}>
                      <div>
                        <label>Type :</label>
                      </div>
                      <input
                        autoComplete="off"
                        onChange={onHandlechange}
                        defaultValue={Articles.type}
                        name="type"
                        className="datevalues"
                        placeholder="Enter The name"
                      ></input>
                    </Col>
                    <Col lg={6} md={6} sm={6}>
                      <div>
                        <label className="datelabel">Author :</label>
                      </div>
                      <input
                        autoComplete="off"
                        onChange={onHandlechange}
                        name="author"
                        value={Articles.author}
                        className="datevalues2"
                        placeholder="Enter The name"
                      ></input>
                    </Col>
                  </Row>
                </div>
                <div className="inputfields">
                  <Row>
                    <Col lg={6} md={6} sm={6}>
                      <div>
                        <label>Created On :</label>
                      </div>
                      <p>{moment(Articles?.createdOn).format("YYYY-MM-DD")}</p>
                    </Col>
                    <Col lg={6} md={6} sm={6}>
                      <div>
                        <label>Published On :</label>
                      </div>
                      <p>
                        {moment(Articles?.PublishedOn).format("YYYY-MM-DD")}
                      </p>
                    </Col>
                  </Row>
                </div>
                <div className="inputfields buttonadjust">
                  <button className="button" onClick={PUT}>
                    <strong>Save</strong>
                  </button>
                  <div className="backbutton">
                    {" "}
                    <Link to="/articles">
                      <button className="button">
                        <strong>
                          <span>
                            <i class="fas fa-arrow-left"></i>
                          </span>{" "}
                          &nbsp;Back
                        </strong>
                      </button>
                    </Link>
                  </div>
                </div>
              </Col>
            </Row>
          </Card>
        ) : null}
      </div>
    </div>
  );
};
export default SetArticles;
