import { React, useEffect, useState } from "react";
import axios from "axios";
import { Button, Container, Row, Col, Table } from "react-bootstrap";
import styles from '../Articles/warehouseDashboard.module.css'

const UpcomingNews = () => {
    const [news, setNews] = useState([])
    const [refresh, setRefresh] = useState(0)
    const rejectHandler = (id) => {
        axios.delete(`${process.env.REACT_APP_BACKEND}/upcoming-news/${id}`).then(() => setRefresh(refresh + 1))
        
    }

    const acceptHandler = (id) => {
        axios.post(`${process.env.REACT_APP_BACKEND}/upcoming-news`, {
            _id: id
        }, 
        {
            headers:{
                authtoken: `${localStorage.getItem("authtoken")}`
            }
        }
        ).then(() => setRefresh(refresh + 1)).catch((err) => console.log(err))
    }
  useEffect(() => {
    axios
      .get(`${process.env.REACT_APP_BACKEND}/upcoming-news?key=0`)
      .then((res) => {
        setNews(res.data)
      })
      .catch((err) => console.log(err));
  }, [refresh]);
  // console.log(news)

  return (
    <div className={styles.tasksTodoContainer} style={{marginTop:"0px"}}>
      <Container>
        <h2 className={styles.textColor}>List Of Upcoming News</h2>
        <Table borderless className={styles.tableSpace}>
          <thead>
            <tr>
              <th className={styles.textColor}>
                S.No <hr className={styles.hrColor} />
              </th>
              <th className={styles.textColor}>
                Image <hr className={styles.hrColor} />
              </th>
              <th className={styles.textColor}>
                Title <hr className={styles.hrColor} />
              </th>
              <th className={`${styles.textColor}`}>
                Author Name <hr className={styles.hrColor} />
              </th>
            </tr>
          </thead>
          <tbody>
            {news
              ? news.map((val, key) => {
                  return (
                    <tr className={styles.tableDataRow}>
                      <td>{key + 1}</td>
                      <td><img src={val.urlToImage} className={styles.UpcomingNewsImage} /></td>
                      <td><span className={styles.UpcomingNewsTitle}>{val.title}</span><br/>{val.description}</td>
                      <td>{val.author}</td>
                      <td>
                            <div className={styles.customizeBtn}>
                              {/* <Link to={`/Editarticlespage/${val._id}`}> */}
                                <Button
                                  variant="Dark"
                                  className={styles.textPColor}
                                  onClick={() => acceptHandler(val._id)}
                                >
                                  Accept
                                </Button>
                              {/* </Link> */}
                            </div>
                          </td>
                          <td>
                            <div className={styles.customizeBtn}>
                              {/* <Link to={`/Editarticlespage/${val._id}`}> */}
                                <Button
                                  variant="Dark"
                                  className={styles.textPColor}
                                  onClick={() => rejectHandler(val._id)}
                                >
                                  Reject
                                </Button>
                              {/* </Link> */}
                            </div>
                          </td>
                    </tr>
                  );
                })
              : null}
          </tbody>
        </Table>
      </Container>
    </div>
  );
};

export default UpcomingNews;
