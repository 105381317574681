import React from "react";
import { Link, useLocation, useHistory } from "react-router-dom";
import { updateUserDetailsAndAccess } from "../actions";
import { useParams } from "react-router-dom";
function Menu(props) {
  const location = useLocation();
  const history = useHistory();
  let id = useParams();
  const setActiveMenu = (route) => {
    return location.pathname === route ? "active" : null;
  };

  return (
    <aside className="main-sidebar sidebar-light-primary">
      {/* Brand Logo */}
      <Link
        to="/"
        className="brand-link"
        style={{ textDecoration: "none", color: "black" }}
      >
        <img
          src="/dist/img/logo.svg"
          alt="Ecom logo"
          className="brand-image "
          style={{ opacity: ".8" }}
        />
        <span className="brand-text font-weight-light ">
          <h5>Ecom</h5>
        </span>
      </Link>
      {/* Sidebar */}
      <div className="sidebar">
        <nav className="mt-2">
          <ul
            className="nav nav-pills nav-sidebar flex-column"
            data-widget="treeview"
            role="menu"
            data-accordion="false"
          >
            <li className="nav-item has-treeview ">
              <Link
                to="/dashboard"
                className={`nav-link ${setActiveMenu("/dashboard")}`}
              >
                <i className="nav-icon fas fa-tachometer-alt" />
                <p>Dashboard</p>
              </Link>
            </li>
            <li className="nav-item">
                <Link
                  to="/upcomingnews"
                  className={`nav-link ${setActiveMenu(
                    "/upcomingnews"
                  )}`}
                >
                  <i className="nav-icon far fa-newspaper" />
                  <p className="text-dark">Upcoming News</p>
                </Link>
              </li>
            <li className="nav-item">
                <Link
                  to="/news"
                  className={`nav-link ${setActiveMenu(
                    "/news"
                  )}`}
                >
                  <i className="nav-icon fas fa-newspaper" />
                  <p className="text-dark">News</p>
                </Link>
              </li>
            <li className="nav-item">
                <Link
                  to="/articles"
                  className={`nav-link ${setActiveMenu(
                    "/articles"
                  )} ${setActiveMenu("/Articlespage")} ${setActiveMenu(
                    "/Editarticlespage"
                  )} ${setActiveMenu(`/Editarticlespage/${id.id}`)}`}
                >
                  <i className="nav-icon fas fa-book" />
                  <p className="text-dark">Articles</p>
                </Link>
              </li>
            <li className="nav-item">
                <Link
                  to="/Events"
                  className={`nav-link ${setActiveMenu(
                    "/Events"
                  )} ${setActiveMenu("/Eventspage")} ${setActiveMenu(
                    "/Editeventpage"
                  )} ${setActiveMenu(`/Editeventpage/${id.id}`)}`}
                >
                  <i className="nav-icon fas fa-newspaper" />
                  <p className="text-dark">Events</p>
                </Link>
              </li>
            <li className="nav-item">
                <Link
                  to="/video"
                  className={`nav-link ${setActiveMenu(
                    "/video"
                  )} ${setActiveMenu("/video")} ${setActiveMenu(
                    `/editvideo/${id.id}`
                  )} ${setActiveMenu("/newvideo")}`}
                >
                  <i className="nav-icon fas fa-newspaper" />
                  <p className="text-dark">Videos</p>
                </Link>
              </li>
              <li className="nav-item">
                <Link
                  to="/jobs"
                  className={`nav-link ${setActiveMenu(
                    "/jobs"
                  )} ${setActiveMenu("/jobs")} ${setActiveMenu(
                    `/editjobs/${id.id}`
                  )} ${setActiveMenu("/createjobs")}`}
                >
                  <i className="nav-icon fas fa-newspaper" />
                  <p className="text-dark">Jobs</p>
                </Link>
              </li>
              <li className="nav-item">
                <Link
                  to="/subscribelist"
                  className={`nav-link ${setActiveMenu(
                    "/subscribelist"
                  )} `}
                >
                  <i className="nav-icon fas fa-newspaper" />
                  <p className="text-dark">Subscribe List</p>
                </Link>
              </li>
              <li className="nav-item">
                <Link
                  to="/contact"
                  className={`nav-link ${setActiveMenu(
                    "/contact"
                  )} ${setActiveMenu(
                    `/contact/${id.id}`
                  )}  `}
                >
                  <i className="nav-icon fas fa-newspaper" />
                  <p className="text-dark">Contact</p>
                </Link>
              </li>
              
            <li className="nav-item">
              <Link to="/login" className={`nav-link`}>
                <i
                  className="nav-icon fas fa-sign-out-alt"
                  aria-hidden="true"
                />
                <p className="text-dark" onClick={()=>localStorage.clear()}>
                  Log Out
                </p>
              </Link>
            </li>
          </ul>
        </nav>
      </div>
    </aside>
  );
}

export default Menu;
