import React, { useState, useEffect } from "react";
import { BrowserRouter as Router, Route, Redirect } from "react-router-dom";
import Dashboard from "../pages/Dashboard";
import Events from "../pages/Events";
import Articles from "../pages/Articles";
import Login from "../pages/Login";
import Signup from "../pages/SignUp";
import Articlespage from "../pages/CreateArticle";
import Eventspage from "../pages/CreateEvents";
import EditArticles from "../pages/EditArticles";
import Editeventpage from "../pages/EditEvents";
import Showvideo from "../pages/videos";
import NewVideo from "../pages/NewVideo";
import EditVideo from "../pages/EditVideo";
import Subscribe from "../pages/subscribe";
import Contact from "../pages/contactus";
import Viewcontact from "../pages/viewcontact";
import Jobs from "../pages/jobs";
import Createjobs from "../pages/createjobs";
import EditJobs from "../pages/Editjobs";
import UpcomingNews from "../pages/UpcomingNews";
import News from "../pages/News";
const Routes = () => {
  const [isLogged, setLogged] = useState(undefined);
  useEffect(() => {
    if (localStorage.getItem("authtoken")) {
      setLogged(true);
    } else {
      setLogged(false);
    }
  }, [localStorage.getItem("authtoken")]);

  if (isLogged !== undefined) {
    return (
      <Router>
        <Route
          path={"/dashboard"}
          exact
          render={() => (isLogged ? <Dashboard /> : <Redirect to="/login" />)}
        />
        <Route path="/" exact render={() => <Redirect to="/login" />}></Route>
        <Route path="/login" exact>
          <Login />
        </Route>
        <Route path="/signup" exact>
          <Signup />
        </Route>
        <Route
          path="/articles"
          exact
          render={() => (isLogged ? <Articles /> : <Redirect to="/login" />)}
        />
        <Route
          path="/Events"
          exact
          render={() => (isLogged ? <Events /> : <Redirect to="/login" />)}
        />
        <Route
          path="/Articlespage"
          exact
          render={() =>
            isLogged ? <Articlespage /> : <Redirect to="/login" />
          }
        />
        <Route
          path="/Editarticlespage/:id"
          exact
          render={() =>
            isLogged ? <EditArticles /> : <Redirect to="/login" />
          }
        />

        <Route
          path="/Editeventpage/:id"
          exact
          render={() =>
            isLogged ? <Editeventpage /> : <Redirect to="/login" />
          }
        />

        <Route
          path="/Eventspage"
          exact
          render={() => (isLogged ? <Eventspage /> : <Redirect to="/login" />)}
        />

        <Route
          path="/video"
          exact
          render={() => (isLogged ? <Showvideo /> : <Redirect to="/login" />)}
        />

        <Route
          path="/newvideo"
          exact
          render={() => (isLogged ? <NewVideo /> : <Redirect to="/login" />)}
        />

        <Route
          path="/editvideo/:id"
          exact
          render={() => (isLogged ? <EditVideo /> : <Redirect to="/login" />)}
        />
        <Route
          path="/subscribelist"
          exact
          render={() => (isLogged ? <Subscribe /> : <Redirect to="/login" />)}
        />
        <Route
          path="/contact"
          exact
          render={() => (isLogged ? <Contact /> : <Redirect to="/login" />)}
        />
        <Route
          path="/jobs"
          exact
          render={() => (isLogged ? <Jobs /> : <Redirect to="/login" />)}
        />
        <Route
          path="/createjobs"
          exact
          render={() => (isLogged ? <Createjobs /> : <Redirect to="/login" />)}
        />
        <Route
          path="/editjobs/:id"
          exact
          render={() => (isLogged ? <EditJobs /> : <Redirect to="/login" />)}
        />
        <Route
          path="/contact/:id"
          exact
          render={() => (isLogged ? <Viewcontact /> : <Redirect to="/login" />)}
        />
        <Route
          path="/upcomingnews"
          exact
          render={() => (isLogged ? <UpcomingNews /> : <Redirect to="/login" />)}
        />
        <Route
          path="/news"
          exact
          render={() => (isLogged ? <News /> : <Redirect to="/login" />)}
        />
      </Router>
    );
  } else return null;
};

export default Routes;
