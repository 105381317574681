import "./Newjobs.css";
import React, { useState } from "react";
import ClassicEditor from "@ckeditor/ckeditor5-build-classic";
import { CKEditor } from "@ckeditor/ckeditor5-react";
import { useHistory, Link } from "react-router-dom";
import axios from "axios";
import S3FileUpload from "react-s3";
import {
  Row,
  Col,
  Card
} from "react-bootstrap";

const SetArticles = () => {
  const initial_state = {
    jobTitle:"",
    jobDescription:"",
    jobContent:"",
    mentionedSalary:"",
    jobPlace:"",
    jobType:"Full-time",
    postedBy:"",
    redirectUrl:"",
    status:"Active",
    urlToImage:"",
    company:""
  };
  const [Articles, setArticles] = useState(initial_state);
  const [ArticleImageUrl, setArticleImageUrl] = useState("");
  const history = useHistory();

  const config = {
    bucketName: "ecomdailynews",
    dirName: "Photos/jobs",
    region: "us-west-1",
    accessKeyId: "AKIAS6RICWHRDKUP4M5K",
    secretAccessKey: "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",
  };

  const onHandlechange = (event) => {
    event.preventDefault();
      setArticles({
        ...Articles,
        [event.target.name]: event.target.value,
      });
  };
  //replace(/\s/g, "");
  const uploadToS3 = (e) => {
    if (
      e.target.files[0].name.match(/\.(jpg|jpeg|png|gif)$/) &&
      e.target.files[0].size < 500000
    ) {
      S3FileUpload.uploadFile(e.target.files[0], config)
        .then((data) => {
          setArticleImageUrl(data);
        })
        .catch((err) => {
          alert("Please re-upload the Image");
        });
    } else {
      alert(`Please select valid format and size`);
    }
  };

  const post = () => {
    let obj = {
      jobTitle: Articles.jobTitle,
      jobDescription: Articles.jobDescription,
      jobContent: Articles.jobContent,
      mentionedSalary:Articles.mentionedSalary,
      jobPlace:Articles.jobPlace,
      jobType:Articles.jobType,
      postedBy: Articles.postedBy,
      redirectUrl:Articles.redirectUrl,
      company:Articles.company,
      status:Articles.status,
      urlToImage: ArticleImageUrl.location,
    };
    axios.post(`${process.env.REACT_APP_BACKEND}/jobs`, obj, {
        headers: {
          authtoken: `${localStorage.getItem("authtoken")}`,
        },
      })
      .then((res) => {
        history.push("/jobs");
      })
      .catch((err) => console.log(err));
  };

  const onhandleChange = (event, editor) => {
    const data = editor.getData();
    setArticles({
      ...Articles,
      jobContent: data,
    });
  };

  return (
    <div className="bodycolor">
      <div
        className="center"
        style={{ marginTop: "10px" }}
        enctype="multipart/form-data"
      >
        <Card autocomplete="off">
          <Row>
            <Col lg={1} sm={1}></Col>
            <Col>
              <h3 className="heading">
                <strong>Create New Jobs</strong>
              </h3>
              <div className="inputfields">
                <div>
                  <label>Job Title :</label>
                </div>
                <input
                  autoComplete="off"
                  className="inputvalues"
                  name="jobTitle"
                  onChange={onHandlechange}
                  placeholder="Enter The name"
                ></input>
              </div>
              <div className="inputfields">
                <div>
                  <label>Job Description :</label>
                </div>
                <input
                  autoComplete="off"
                  onChange={onHandlechange}
                  name="jobDescription"
                  className="inputvalues"
                  placeholder="Enter The Description"
                ></input>
              </div>
              <div className="inputfields">
                <div>
                  <label>Job Content :</label>
                </div>
                <div
                  style={{
                    backgroundColor: "rgb(240 239 239)",
                    width: "530px",
                  }}
                >
                  <CKEditor
                    editor={ClassicEditor}
                    onChange={onhandleChange}
                    onInit={(editor) => {
                      console.log("Editor is ready to use!", editor);
                    }}
                    config={{
                      ckfinder: {
                        // ${process.env.REACT_APP_BACKEND}
                        uploadUrl: `${process.env.REACT_APP_BACKEND}/articles/upload`,
                      },
                    }}
                  />
                </div>
              </div>
              <div>
                <label className="headerlabel">Header Image</label>   <span>
              &nbsp; Note: Image less than 500kb and extension ".jpg .jpeg .png"
              </span>
              </div>
              <input
                autoComplete="off"
                onChange={uploadToS3}
                name="urlToImage"
                className="imageheader"
                type="file"
              ></input>
              <div className="inputfields">
                <div>
                  <label>Redirect URL :</label>
                </div>
                <input
                  autoComplete="off"
                  onChange={onHandlechange}
                  name="redirectUrl"
                  className="inputvalues"
                  placeholder="Example:- Business, Technology, Sports etc"
                ></input>
              </div>
              <div className="inputfields">
                <div>
                  <label>Company Name :</label>
                </div>
                <input
                  autoComplete="off"
                  onChange={onHandlechange}
                  name="company"
                  className="inputvalues"
                  placeholder="Example:- Business, Technology, Sports etc"
                ></input>
              </div>
              <div className="inputfields">
                <Row>
                  <Col lg={6} md={6} sm={6}>
                    <div >
                      <label>Job Type :</label>
                    </div>
                    <select name="jobType" value={Articles.jobtype} style={{padding: "10px",border: "0px solid"}} onChange={onHandlechange}>
                      <option  value="Full-Time">Full-Time</option>
                      <option  value="Part-Time">Part-Time</option>
                      <option  value="Not-Mentioned">Not-Mentioned</option>
                    </select>
                  </Col>
                  <Col lg={6} md={6} sm={6}>
                    <div>
                      <label className="datelabel">Job Place :</label>
                    </div>
                    <input
                      autoComplete="off"
                      onChange={onHandlechange}
                      name="jobPlace"
                      className="typevalues"
                      placeholder="Enter The name"
                    ></input>
                  </Col>
                </Row>
              </div>
              <div className="inputfields">
                <Row>
                  <Col lg={6} md={6} sm={6}>
                    <div >
                      <label>Status :</label>
                    </div>
                    <select name="status" value={Articles.status} style={{padding: "10px 35px",border: "0px solid"}} onChange={onHandlechange}>
                      <option value="Active">Active</option>
                      <option value="InActive">InActive</option>
                      <option value="Expired">Expired</option>
                    </select>
                  </Col>
                  <Col lg={6} md={6} sm={6}>
                    <div>
                      <label className="datelabel">Post By :</label>
                    </div>
                    <input
                      autoComplete="off"
                      onChange={onHandlechange}
                      name="postedBy"
                      className="typevalues"
                      placeholder="Enter The name"
                    ></input>
                  </Col>
                  </Row>
                  </div>
              <div className="inputfields">
                <Row>
                  <Col lg={6} md={6} sm={6}>
                    <div style={{display:"flex"}}>
                      <label className="datelabel" >Salary :</label><span><p>&nbsp;Ex:$1000 - 3000</p></span>
                    </div>
                    <input
                      autoComplete="off"
                      onChange={onHandlechange}
                      name="mentionedSalary"
                      className="typevalues"
                      placeholder="Salary Value"
                    ></input>
                  </Col>
                </Row>
              </div>
              <div className="inputfields buttonadjust">
                <button className="button" onClick={post}>
                  <strong>Save</strong>
                </button>
                <div className="backbutton">
                  {" "}
                  <Link to="/articles">
                    <button className="button">
                      <strong>
                        <span>
                          <i class="fas fa-arrow-left"></i>
                        </span>{" "}
                        &nbsp;Back
                      </strong>
                    </button>
                  </Link>
                </div>
              </div>
            </Col>
          </Row>
        </Card>
      </div>
    </div>
  );
};
export default SetArticles;
