import "./EditVideo.css";
import { Card, Row, Col } from "react-bootstrap";
import React, { useState, useEffect } from "react";
import axios from "axios";
import moment from "moment";
import { useParams, Link, useHistory } from "react-router-dom";
import { CKEditor } from "@ckeditor/ckeditor5-react";
import ClassicEditor from "@ckeditor/ckeditor5-build-classic";

const SetArticles = () => {
  const history = useHistory();
  const [Articles, setArticles] = useState([]);
  let id = useParams();

  const onhandleChange = (event, editor) => {
    const data = editor.getData();
    setArticles({
      ...Articles,
      description: data,
    });
  };

  const onHandlechange = (event) => {
    event.preventDefault();
    setArticles({
      ...Articles,
      [event.target.name]: event.target.value,
    });
  };
  const fetching_videos=async()=>{
    await axios
      .get(`${process.env.REACT_APP_BACKEND}/videos/${id.id}`)
      .then((res) => {
        setArticles(res.data.data);
      })
      .catch((err) => console.log(err));
  }
  useEffect(() => {
    fetching_videos()
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [Articles]);

  const post = () => {
    axios
      .put(`${process.env.REACT_APP_BACKEND}/videos/${id.id}`, {
        description: Articles.description,
        mediaUrl: Articles.mediaUrl,
        title: Articles.title,
        type: Articles.type,
      },{
        headers: {
          authtoken: `${localStorage.getItem("authtoken")}`,
        },
      })
      .then((data) => {
        alert("Successfully edited video");
        history.push("/video");
      })
      .catch((err) => console.log(err));
  };

  // const { quill, quillRef } = useQuill();
  return (
    <div className="bodycolor">
      <div className="center" style={{ marginTop: "10px" }}>
        {Articles._id !== null ? (
          <Card>
            <Row>
              <Col lg={1} sm={1} ></Col>
              <Col>
                <h3 className="heading">
                  <strong>Edit Video</strong>
                </h3>
                <div className="inputfields">
                  <div>
                    <label>Title :</label>
                  </div>
                  <input
                    value={Articles?.title}
                    className="inputvalues"
                    name="title"
                    onChange={onHandlechange}
                    placeholder="Enter The name"
                  ></input>
                </div>
                <div className="inputfields">
                  <div>
                    <label>Description :</label>
                  </div>
                  <CKEditor
                    data={Articles?.description}
                    editor={ClassicEditor}
                    onChange={onhandleChange}
                    onInit={(editor) => {}}
                    config={{
                      ckfinder: {
                        // ${process.env.REACT_APP_BACKEND}
                        uploadUrl: `${process.env.REACT_APP_BACKEND}/articles/upload`,
                      },
                    }}
                  />
                </div>
                <div className="inputfields">
                  <div>
                    <label>Media URL:</label>
                  </div>
                  <input
                    value={Articles?.mediaUrl}
                    onChange={onHandlechange}
                    name="mediaUrl"
                    className="inputvalues"
                    placeholder="Enter The Description"
                  ></input>
                </div>

                <div className="inputfields">
                  <Row>
                    <Col lg={6} md={6} sm={6}>
                      <div>
                        <label>Type :</label>
                      </div>
                      <input
                        value={Articles?.type}
                        onChange={onHandlechange}
                        name="type"
                        className="datevalues"
                        placeholder="Enter The name"
                      ></input>
                    </Col>
                    <Col lg={6} md={6} sm={6}>
                      <div>
                        <label>Created On :</label>
                      </div>
                      <input
                        value={moment(Articles?.createdOn).format("YYYY-MM-DD")}
                        onChange={onHandlechange}
                        name="createdon"
                        className="datevalues"
                        type="date"
                        placeholder="Enter The name"
                      ></input>
                    </Col>
                  </Row>
                </div>
                <div className="inputfields buttonadjust">
                  <button className="button" onClick={post}>
                    <strong>Save</strong>
                  </button>
                  <div className="backbutton">
                    {" "}
                    <Link to="/video">
                      <button className="button">
                        <strong>
                          <span>
                            <i class="fas fa-arrow-left"></i>
                          </span>{" "}
                          &nbsp;Back
                        </strong>
                      </button>
                    </Link>
                  </div>
                </div>
              </Col>
            </Row>
          </Card>
        ) : null}
      </div>
    </div>
  );
};
export default SetArticles;
