import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import styles from "./warehouseDashboard.module.css";
import {
  Button,
  Container,
  Row,
  Col,
  Table,
} from "react-bootstrap";
import axios from "axios";
import moment from "moment";


const Articles = () => {

  const [Articlesdata, SetArticlesdata] = useState([]);

  useEffect(() => {
    axios
      .get(`${process.env.REACT_APP_BACKEND}/articles?key=2`)
      .then((res) => {
        SetArticlesdata(res.data.articles);
      })
      .catch((err) => console.log(err));
  }, []);

  const dateformat = (value) => {
    return moment(value).format("MMM-DD-YYYY");
  };

  return (
    <div className={`${styles.container} content-wrapper`} style={{marginTop:"0px"}}>
      <Container>
        <Row>
          <Col className={styles.buttonContainer}>
            <Link to="/Articlespage">
              <Button className={styles.mainButton} variant="secondary" style={{marginTop:"10px"}}>
                New Article
              </Button>
            </Link>
          </Col>
        </Row>

        <div className={styles.tasksTodoContainer}>
          <Container >
            <h2 className={styles.textColor}>List Of Articles</h2>
            <Table borderless className={styles.tableSpace} >
              <thead>
                <tr>
                  <th className={styles.textColor}>
                    S.No <hr className={styles.hrColor} />
                  </th>
                  <th className={styles.textColor}>
                    Title <hr className={styles.hrColor} />
                  </th>
                  <th className={`${styles.textColor}`}>
                    Author Name <hr className={styles.hrColor} />
                  </th>
                  <th className={styles.textColor}>
                    CreatedOn <hr className={styles.hrColor} />
                  </th>
                  <th className={styles.textColor}>
                    PublishedOn <hr className={styles.hrColor} />
                  </th>
                  <th className={styles.textColor}>
                    Location <hr className={styles.hrColor} />
                  </th>
                  {/* <th className={styles.textColor}><hr className={styles.hrColor} /></th> */}
                </tr>
              </thead>
              <tbody>
                {/* {renderTasks(tasksData)} */}
                {Articlesdata
                  ? Articlesdata.map((val, key) => {
                   
                      return (
                        <tr className={styles.tableDataRow} >
                          <td>{key + 1}</td>
                          <td>{val.title}</td>
                          <td>{val.author}</td>
                          <td className={styles.tableDataRow} >{dateformat(val.createdOn)}</td>
                          <td className={styles.tableDataRow} >{dateformat(val.PublishedOn)}</td>
                          <td>
                            <div className={styles.customizeBtn}>
                              <Link to={`/Editarticlespage/${val._id}`}>
                                <Button
                                  variant="light"
                                  className={styles.textPColor}
                                >
                                  Edit Articles
                                </Button>
                              </Link>
                            </div>
                          </td>
                        </tr>
                      );
                    }
                
                    )
                  : null}
              </tbody>
            </Table>
          </Container>
        </div>
      </Container>
      <div></div>
    </div>
  );
};

export default Articles;
