import React, { useState,useEffect } from "react";
import axios from "axios";
import {
    Button,
    Container,
    Row,
    Col,
    Table,
  } from "react-bootstrap";
import styles from "./warehouseDashboard.module.css";
import moment from "moment";

const Subscribe =()=>{

const [Subscribe,setvalue]=useState()
const dateformat = (value) => {
    return moment(value).format("MMMM-DD-YYYY");
  };
useEffect(()=>{
    axios.get(`${process.env.REACT_APP_BACKEND}/subscribe?key=0`)
    .then(data=>{
        setvalue(data.data.subUsers)
    })
    .catch(err=>console.log(err))
},[])

    return(
        <div className={`${styles.container}  content-wrapper`}>
          <Container>
          <div className={styles.tasksTodoContainer}>
            <h2 className={styles.textColor} style={{marginLeft:"450px"}}>subscribers List</h2>
            <Table borderless className={styles.tableSpace}>
              <thead>
                <tr>
                  <th className={styles.textColor}>
                    S.No <hr className={styles.hrColor} />
                  </th>
                  <th className={styles.textColor}>
                    Name <hr className={styles.hrColor} />
                  </th>
                  <th className={`${styles.textColor}`}>
                    Email <hr className={styles.hrColor} />
                  </th>
                  <th className={styles.textColor}>
                    CreatedOn <hr className={styles.hrColor} />
                  </th>
                </tr>
              </thead>
              <tbody>
                {/* {renderTasks(tasksData)} */}
                {Subscribe
                  ? Subscribe.map((val, key) => {
                      return (
                        <tr className={styles.tableDataRow} key={key}>
                          <td>{key + 1}</td>
                          <td>{val.userName}</td>
                          <td>{val.email}</td>
                          <td>{dateformat(val.createdOn)}</td>
                        </tr>
                      );
                    })
                  : null}
              </tbody>
            </Table>
            </div>
          </Container>
        </div>
     
    )
}
export default Subscribe;