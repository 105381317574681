import "./Events.css";
import axios from "axios";
import {  Card, Row, Col, Container } from "react-bootstrap";
import React, {useState } from "react";
import { ToastContainer} from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { CKEditor } from "@ckeditor/ckeditor5-react";
import ClassicEditor from "@ckeditor/ckeditor5-build-classic";
import S3FileUpload from "react-s3";
import { useHistory, Link } from "react-router-dom";
const AWS = require("aws-sdk");
const SetEvents = () => {
  const [Articles, setArticles] = useState();
  const [ArticleImageUrl, setArticleImageUrl] = useState([]);
  const [preview, setPreview] = useState([]);
  const fileobj = [];
  const history = useHistory();

  const config = {
    bucketName: "ecomdailynews",
    dirName: "Photos/events",
    region: "us-west-1",
    accessKeyId: "AKIAS6RICWHRDKUP4M5K",
    secretAccessKey: "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",
  };

  const onHandlechange = (event) => {
    event.preventDefault();
    if (event.target.name === "tags") {
      setArticles({
        ...Articles,
        [event.target.name]: [event.target.value],
      });
    } else if (event.target.type === "datetime-local") {
      setArticles({
        ...Articles,
        [event.target.name]: new Date(event.target.value).getTime(),
      });
    } else {
      setArticles({
        ...Articles,
        [event.target.name]: event.target.value,
      });
    }
  };

  const uploadToS3 = async (e) => {
    let files = e.target.files;
    fileobj.push(files);
    let reader;

    for (var i = 0; i < fileobj[0].length; i++) {
      const imageFile = e.target.files[i];
      if (
        imageFile.name.match(/\.(jpg|jpeg|png|gif)$/) &&
        imageFile.size < 500000
      ) {
        await S3FileUpload.uploadFile(e.target.files[i], config).then(
          (data) => {
            ArticleImageUrl.push(data);
            setArticleImageUrl([...new Set(ArticleImageUrl)]);
          }
        );
        reader = new FileReader();
        reader.readAsDataURL(fileobj[0][i]);
        reader.onload = (event) => {
          preview.push(event.target.result); // update the array instead of replacing the entire value of preview
          setPreview([...new Set(preview)]); // spread into a new array to trigger rerender
        };
      } else {
        alert(`Please select valid format and size`);
      }
    }
  };
  const post = async () => {
    let ImagesUrl = ArticleImageUrl.filter((val) => val.location != null);
    let obj = {
      eventTitle: Articles.eventTitle,
      eventDescription: Articles.eventDescription,
      eventContent: Articles.eventContent,
      startEvent: Articles.startEvent,
      endEvent: Articles.endEvent,
      mediaUrl: ImagesUrl.map((val) => val.location),
      redirectUrl: Articles.redirectUrl,
    };


    await axios
      .post(`${process.env.REACT_APP_BACKEND}/events`, obj,{
        headers: {
          authtoken: `${localStorage.getItem("authtoken")}`,
        },
      })
      .then((res) => history.push("/Events"))
      .catch((err) => console.log(err));
  };

  const onhandleChange = (event, editor) => {
    const data = editor.getData();
    setArticles({ ...Articles, eventContent: data });
  };
  const onDelete = (index) => {
    setPreview((prev) => {
      let arr = prev;
      delete arr[index];
      setPreview(arr);
    });
    setArticleImageUrl((data) => {
      let img = data;
      const s3 = new AWS.S3({
        accessKeyId: config.accessKeyId,
        secretAccessKey: config.secretAccessKey,
        region: "us-west-1",
      });
      let params = {
        Bucket: "ecomdailynews",
        Key: `${img[index]?.key}`,
      };
      s3.deleteObject(params, function (err, data) {
        if (err) console.log(err, err.stack);
        // error
        else {
          delete img[index];
          setArticleImageUrl(img);
        } // deleted
      });
      return;
    });
  };
  return (
    <div className="bodycolor">
      <div className="centerpage" style={{ marginTop: "10px" }}>
        <Card>
          <Row>
            <Col lg={1} sm={1} ></Col>
            <Col>
              <h3 className="heading">
                <strong>Create New Events</strong>
              </h3>
              <div className="inputfields">
                <div>
                  <label>Title :</label>
                </div>
                <input
                  className="inputvalues"
                  name="eventTitle"
                  onChange={onHandlechange}
                  placeholder="Enter The name"
                ></input>
              </div>
              <div className="inputfields">
                <div>
                  <label>Description :</label>
                </div>
                <input
                  onChange={onHandlechange}
                  name="eventDescription"
                  className="inputvalues"
                  placeholder="Enter The Description"
                ></input>
                <ToastContainer />
              </div>
              <div className="inputfields">
                <div>
                  <label>Content :</label>
                </div>
                <div
                  style={{
                    backgroundColor: "rgb(240 239 239)",
                    width: "530px",
                  }}
                >
                  <CKEditor
                    editor={ClassicEditor}
                    onChange={onhandleChange}
                    onInit={(editor) => {
                      console.log("Editor is ready to use!", editor);
                    }}
                    config={{
                      ckfinder: {
                        // ${process.env.REACT_APP_BACKEND}
                        uploadUrl: `${process.env.REACT_APP_BACKEND}/articles/upload`,
                      },
                    }}
                  />
                </div>
              </div>
              <div className="inputfields">
                <div>
                  <label>Media Url :</label>&nbsp;<span> Image  less than 500kb and extension ".jpg .jpeg .png"</span>
                </div>
                <input
                  onChange={uploadToS3}
                  type="file"
                  name="mediaUrl"
                  className="inputvalues"
                  placeholder="Enter The Url"
                  multiple
                ></input>
              </div>
              <div style={{ marginLeft: "60px", marginTop: "20px" }}>
                <Row>
                  {preview
                    ? preview.map((url, index) => (
                        <div>
                          <Container>
                            <Col class="col-xs-6 col-md-10">
                              <img
                                src={url}
                                alt="..."
                                key={index}
                                style={{ height: "100px", width: "200px" }}
                              />

                              <button
                                type="button"
                                onClick={() => onDelete(index)}
                                class="close"
                                aria-label="Close"
                              >
                                <span aria-hidden="true">&times;</span>
                              </button>
                            </Col>
                            <div style={{ marginBottom: "10px" }}></div>
                          </Container>
                        </div>
                      ))
                    : null}
                </Row>
              </div>
              <div className="inputfields">
                <div>
                  <label>Redirect Url :</label>
                </div>
                <input
                  onChange={onHandlechange}
                  name="redirectUrl"
                  className="inputvalues"
                  placeholder="Enter The Redirect Url"
                ></input>
              </div>
              <div className="inputfields">
                <div>
                  <label>Tags :</label>
                </div>
                <input
                  name="tags"
                  className="inputvalues"
                  placeholder="Enter The Tags"
                ></input>
              </div>

              <div className="inputfields">
                <Row>
                  <Col lg={6} md={6} sm={6}>
                    <div>
                      <label>Start Event :</label>
                    </div>
                    <input
                      onChange={onHandlechange}
                      name="startEvent"
                      className="datevalues"
                      type="datetime-local"
                    ></input>
                  </Col>
                  <Col lg={6} md={6} sm={6}>
                    <div>
                      <label className="datelabel">End Event :</label>
                    </div>
                    <input
                      onChange={onHandlechange}
                      name="endEvent"
                      className="datevalues2"
                      type="datetime-local"
                    ></input>
                  </Col>
                </Row>
              </div>
              <div className="inputfields buttonadjust">
                <button className="button" onClick={post}>
                  <strong>Save</strong>
                </button>
                <div className="backbutton">
                  {" "}
                  <Link to="/Events">
                    <button
                      className="button"
                      onClick={() => history.push("/dashboard")}
                    >
                      <strong>
                        <span>
                          <i class="fas fa-arrow-left"></i>
                        </span>{" "}
                        &nbsp;Back
                      </strong>
                    </button>
                  </Link>
                </div>
              </div>
            </Col>
          </Row>
        </Card>
      </div>
    </div>
  );
};
export default SetEvents;
