import React from "react";

export default function Footer() {
  return (
    <footer className="main-footer" style={{textAlign:"center"}}>
      <strong>
        Copyright &copy; 2020-2021 <a href="/">Ecom Daily News </a>.
      </strong>
      All rights reserved.
    </footer>
  );
}
