import React from 'react';
import { Alert } from 'react-bootstrap';

const AlertInfo = (props) => {
  return (
    <div style={{ paddingLeft: '24%', paddingRight: '24%' }}> 
      <Alert variant={`${props.color}`} >{props.message}</Alert>
    </div>
  );
}

export default AlertInfo;