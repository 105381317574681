import React, { useState,useEffect } from "react";
import {useParams,Link} from  "react-router-dom";
import axios from "axios";
import {
    Button,
    Container,
    Row,
    Col,
    Table,
  } from "react-bootstrap";
import styles from "./warehouseDashboard.module.css";
import moment from "moment";

const Subscribe =()=>{
const id=useParams()
const [Subscribe,setvalue]=useState()
const dateformat = (value) => {
    return moment(value).format("MMMM-DD-YYYY");
  };
useEffect(()=>{
    axios.get(`${process.env.REACT_APP_BACKEND}/contact_us/${id.id}`)
    .then(data=>{
        setvalue(data.data.data)
        console.log(data.data.data)
    })
    .catch(err=>console.log(err))
},[])

    return(
        <div className={`${styles.container}  content-wrapper`}>
        <Container>
        <div className={styles.tasksTodoContainer}>
          <h2 className={styles.textColor} style={{marginLeft:"450px"}}>Contact List</h2>
          <Table borderless className={styles.tableSpace}>
            <tbody>
           <tr><td>FirstName:{Subscribe?.firstName}</td></tr>
            <tr><td>LastName:{Subscribe?.lastName}</td></tr>
            <tr><td>Email:{Subscribe?.email}</td></tr>
            <tr><td>Mobile:{Subscribe?.mobile}</td></tr>
            <tr><td>Raise date:{dateformat(Subscribe?.createdOn)}</td></tr>
            <tr><td>Message:</td></tr>
            <tr><p style={{marginLeft:"60px",marginTop:"-10px"}}>
            {Subscribe?.message}
                </p></tr>
            

           
            </tbody>
          </Table>
          </div>
        </Container>
      </div>
     
    )
}
export default Subscribe;