import Footer from "../shared/Footer";
import Header from "../shared/Header";
import Menu from "../shared/Menu";
import Body from "../components/NewVideo/NewVideo";

const Events = () => {
  return (
    <div>
      <Header />
      <Menu />
      <Body />
    </div>
  );
};

export default Events;
