import React from 'react'
import Footer from '../shared/Footer'
import Header from '../shared/Header'
import Menu from '../shared/Menu'
import Body from '../components/News/index'

const News = () => {
    return (
        <div>
            <Header />
            <Menu />
            <Body />
            <Footer />
        </div>
    )
}

export default News
