import { React, useEffect, useState } from "react";
import axios from "axios";
import { Button, Container, Row, Col, Table } from "react-bootstrap";
import styles from "../Articles/warehouseDashboard.module.css";

const News = () => {
  const [news, setNews] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [Refresh, setRefresh] = useState(0)
  const deleteHandler = (id) => {
      axios.delete(`${process.env.REACT_APP_BACKEND}/news_articles/${id}`, {
        
            headers:{
                authtoken: `${localStorage.getItem("authtoken")}`
            }
        
      }).then(() => setRefresh(Refresh + 1)).catch(err => console.log(err))
  };

  useEffect(() => {
    function fetchNews() {
      axios
        .get(
          `${process.env.REACT_APP_BACKEND}/news_articles?key=2&pgSize=50&pgNumber=${currentPage}`
        )
        .then((res) => {
          setNews(res.data.news);
        })
        // .catch((err) => console.log(err));
    }
    fetchNews();
  }, [currentPage, Refresh]);

  return (
    <div className={styles.tasksTodoContainer} style={{ marginTop: "0px" }}>
      <Container>
        <h2 className={styles.textColor}>List Of News</h2>
        <Table borderless className={styles.tableSpace}>
          <thead>
            <tr>
              <th className={styles.textColor}>
                S.No <hr className={styles.hrColor} />
              </th>
              <th className={styles.textColor}>
                Image <hr className={styles.hrColor} />
              </th>
              <th className={styles.textColor}>
                Title <hr className={styles.hrColor} />
              </th>
            </tr>
          </thead>
          <tbody>
            {news
              ? news.map((val, key) => {
                  return (
                    <tr className={styles.tableDataRow}>
                      <td>{key + 1}</td>
                      <td>
                        <img
                          src={val.urlToImage}
                          className={styles.UpcomingNewsImage}
                        />
                      </td>
                      <td>
                        <span className={styles.UpcomingNewsTitle}>
                          {val.title}
                        </span>
                        <br />
                        {val.description}
                      </td>
                      <td>
                        <div className={styles.customizeBtn}>
                          <Button
                            margin="auto"
                            variant="Dark"
                            className={styles.textPColor}
                            onClick={() => deleteHandler(val._id)}
                          >
                            Delete
                          </Button>
                        </div>
                      </td>
                    </tr>
                  );
                })
              : null}
          </tbody>
        </Table>
      </Container>
      <div className={styles.btnWrapper}>
        {currentPage > 1 ? (
          <Button
            className={styles.nxtBtn}
            variant="Dark"
            onClick={() => {
              setNews([]);
              setCurrentPage(currentPage - 1);
            }}
          >
            Previous
          </Button>
        ) : null}
        <Button
          className={styles.nxtBtn}
          variant="Dark"
          onClick={() => {
            setNews([]);
            setCurrentPage(currentPage + 1);
          }}
        >
          Next
        </Button>
      </div>
    </div>
  );
};

export default News;
