import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import styles from "./warehouseDashboard.module.css";
import {
  Button,
  Container,
  Row,
  Col,
  Table,
} from "react-bootstrap";
import axios from "axios";
import moment from "moment";


const Articles = () => {

  const [Articlesdata, SetArticlesdata] = useState([]);


  // form validation rules


  // Form validation


  useEffect(() => {
    axios
      .get(`${process.env.REACT_APP_BACKEND}/videos?key=0`)
      .then((res) => {
        SetArticlesdata(res.data.vids);
      })
      .catch((err) => console.log(err));
  }, []);


  const dateformat = (value) => {
    return moment(value).format("MMM-DD-YYYY");
  };


  return (
    <div className={`${styles.container} content-wrapper`}>
      <Container>
        <Row>
          <Col className={styles.buttonContainer}>
            <Link to="/newvideo">
              <Button className={styles.mainButton} variant="secondary">
                New Video
              </Button>
            </Link>
          </Col>
        </Row>

        <div className={styles.tasksTodoContainer}>
          <Container>
            <h2 className={styles.textColor}>List Of Videos</h2>
            <Table borderless className={styles.tableSpace}>
              <thead>
                <tr>
                  <th className={styles.textColor}>
                    S.No <hr className={styles.hrColor} />
                  </th>
                  <th className={styles.textColor}>
                    Media Title <hr className={styles.hrColor} />
                  </th>
                  <th className={`${styles.textColor}`}>
                    Type <hr className={styles.hrColor} />
                  </th>
                  <th className={styles.textColor}>
                    CreatedOn <hr className={styles.hrColor} />
                  </th>
                  <th className={styles.textColor}>
                    Edit <hr className={styles.hrColor} />
                  </th>
                  {/* <th className={styles.textColor}><hr className={styles.hrColor} /></th> */}
                </tr>
              </thead>
              <tbody>
                {/* {renderTasks(tasksData)} */}
                {Articlesdata.length > 0
                  ? Articlesdata.map((val, key) => {
                      return (
                        <tr className={styles.tableDataRow}>
                          <td>{key + 1}</td>
                          <td>{val.title}</td>
                          <td className={styles.tableDataRow}>
                            <Row>
                              <p>{val.type}</p>
                            </Row>
                          </td>
                          <td>{dateformat(val.createdOn)}</td>
                          <td>
                            <div className={styles.customizeBtn}>
                              <Link to={`/editvideo/${val._id}`}>
                                <Button
                                  variant="light"
                                  className={styles.textPColor}
                                >
                                  Edit Video
                                </Button>
                              </Link>
                            </div>
                          </td>
                        </tr>
                      );
                    })
                  : null}
              </tbody>
            </Table>
          </Container>
        </div>
      </Container>
      <div></div>
    </div>
  );
};

export default Articles;
