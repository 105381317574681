import React from 'react';
import { Spinner } from 'react-bootstrap';

const LoaderInfo = () => {
  return (
    <div style={{ position: 'absolute', top: 0, width: '100%', height: '100%', backgroundColor: 'rgba(0,0,0,0.6)' }}>
      <Spinner animation="border" variant="info" style={{ marginLeft: '45%', marginTop: '20%', height: '100px', width: '100px' }} />
    </div>
  );
}

export default LoaderInfo;