import React, { useState, useEffect } from "react";
import { Link, useHistory } from "react-router-dom";
import { connect } from "react-redux";
import { Container } from "react-bootstrap";
import axios from "axios";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as Yup from "yup";

import AlertInfo from "../shared/AlertInfo";
import LoaderInfo from "../shared/LoaderInfo";

const SignUp = (props) => {
  const history = useHistory();

  // form validation rules
  const validationSchema = Yup.object().shape({
    email: Yup.string().required("Email is required").email("Email is invalid"),
    password: Yup.string()
      .min(6, "Password must be at least 6 characters")
      .required("Password is required"),
    confirmPassword: Yup.string()
      .oneOf([Yup.ref("password"), null], "Passwords must match")
      .required("Confirm Password is required"),
  });

  const [regStatus, setStatus] = useState(undefined);

  // Send post request to register
  const postRegister = async (data) => {
    const postReg = await axios
      .post(`${process.env.REACT_APP_BACKEND}/api/staff/signup`, {
        ...data,
      })
      .catch((err) => {
        return setStatus({
          status: err.response.data.status,
          msg: err.response.data.message,
        });
      });

    if (postReg) {
      setStatus({ status: postReg.data.status, msg: postReg.data.data });
    }
  };

  // Set data to Session and Local Storage

  useEffect(() => {
    if (regStatus !== undefined && regStatus.status === "OK") {
      setSessionAndLocalStorage();
    }
  }, [regStatus]);

  // Set userDetails and accessToken to localstorage
  const setSessionAndLocalStorage = () => {
    localStorage.setItem("accessToken", regStatus.msg.accessToken);
    axios
      .get(`${process.env.REACT_APP_BACKEND}/api/staff/me`, {
        headers: {
          staffAccessToken: regStatus.msg.accessToken,
        },
      })
      .then((fetchedData) => {
        let scrapedData = fetchedData.data.data;
        localStorage.setItem(
          "staffData",
          JSON.stringify({
            email: scrapedData.email,
            id: scrapedData.id,
            staff_role_id: scrapedData.staff_role_id,
            system_operating_country_id:
              scrapedData.system_operating_country_id,
          })
        );

        localStorage.setItem("isLoggedIn", JSON.stringify(true));

        props.updateUserDetailsAndAccess({
          isLoggedIn: true,
          accessToken: regStatus.msg.accessToken,
          userDetails: {
            email: scrapedData.email,
            id: scrapedData.id,
            staff_role_id: scrapedData.staff_role_id,
            system_operating_country_id:
              scrapedData.system_operating_country_id,
          },
        });
      });
  };

  // Show signup status to user
  const renderStatus = () => {
    if (regStatus !== undefined && regStatus.status === "OK") {
      return (
        <div>
          {history.push("/dashboard")}
          <LoaderInfo />
          <AlertInfo
            color="success"
            message={`Successfully registered email ${regStatus.msg.user.email}`}
          />
        </div>
      );
    } else if (regStatus !== undefined && regStatus.status === "FAIL") {
      return (
        <div>
          <AlertInfo color="danger" message={`${regStatus.msg}`} />
        </div>
      );
    } else <LoaderInfo />;
  };

  // Form validation
  const { register, handleSubmit, errors } = useForm({
    resolver: yupResolver(validationSchema),
  });
  const SubmitForm = (data) => {
    console.log(data);
    postRegister(data);
  };

  return (
    <div>
      <Container style={{ width: "100%" }}>
        <div
          style={{ marginTop: "30px", paddingLeft: "20%", paddingRight: "20%" }}
        >
          <h1>Sign up</h1>

          <form onSubmit={handleSubmit(SubmitForm)}>
            <div className="form-row">
              <div className="form-group col">
                <label>
                  Email <span style={{ color: "red" }}>*</span>
                </label>
                <input
                  name="email"
                  type="text"
                  ref={register}
                  className={`form-control ${errors.email ? "is-invalid" : ""}`}
                />
                <div className="invalid-feedback">{errors.email?.message}</div>
              </div>
            </div>
            <div className="form-row">
              <div className="form-group col">
                <label>
                  Password <span style={{ color: "red" }}>*</span>
                </label>
                <input
                  name="password"
                  type="password"
                  ref={register}
                  className={`form-control ${
                    errors.password ? "is-invalid" : ""
                  }`}
                />
                <div className="invalid-feedback">
                  {errors.password?.message}
                </div>
              </div>
              <div className="form-group col">
                <label>
                  Confirm Password <span style={{ color: "red" }}>*</span>
                </label>
                <input
                  name="confirmPassword"
                  type="password"
                  ref={register}
                  className={`form-control ${
                    errors.confirmPassword ? "is-invalid" : ""
                  }`}
                />
                <div className="invalid-feedback">
                  {errors.confirmPassword?.message}
                </div>
              </div>
            </div>
            <div className="form-group">
              <button
                type="submit"
                className="btn btn-success"
                style={{ width: "50%", display: "inline-block" }}
              >
                Sign Up
              </button>
              <p
                className="forgot-password text-right"
                style={{ width: "50%", display: "inline-block" }}
              >
                <Link to="/login">Already registered sign in?</Link>
              </p>
            </div>
          </form>
        </div>
      </Container>
      {renderStatus()}
    </div>
  );
};

export default SignUp;
