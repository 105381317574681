/* eslint-disable array-callback-return */
import "./EditEvents.css";
import { Card, Row, Col, Container } from "react-bootstrap";
import axios from "axios";
import { useParams, Link, useHistory } from "react-router-dom";
import React, { useEffect, useState } from "react";
import moment from "moment";
import { CKEditor } from "@ckeditor/ckeditor5-react";
import ClassicEditor from "@ckeditor/ckeditor5-build-classic";
import S3FileUpload from "react-s3";
import "./EditEvents.css";
const AWS = require("aws-sdk");

const SetArticles = () => {
  const [ArticleImageUrl, setArticleImageUrl] = useState([]);
  const [Articles, setArticles] = useState();
  const [preview, setPreview] = useState([]);
  const fileobj = [];
  const history = useHistory();
  let id = useParams();
  const onhandleChange = (event, editor) => {
    const data = editor.getData();
    setArticles({
      ...Articles,
      eventContent: data,
    });
  };

  const config = {
    bucketName: "ecomdailynews",
    dirName: "Photos/events",
    region: "us-west-1",
    accessKeyId: "AKIAS6RICWHRDKUP4M5K",
    secretAccessKey: "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",
  };

  const feth_details=async ()=>{
    await axios
    .get(`${process.env.REACT_APP_BACKEND}/events/${id.id}`)
    .then((res) => {
      setArticles(res.data.data);
      setPreview(res.data.data.mediaUrl.map((val) => val));
      setArticleImageUrl(res.data.data.mediaUrl.map((val) => val));
    })
    .catch((err) => console.log(err));
  }

  useEffect( () => {
    feth_details()
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const onHandlechange = (event) => {
    if (event.target.name === "tags") {
      setArticles({
        ...Articles,
        [event.target.name]: [event.target.value],
      });
    } else {
      setArticles({
        ...Articles,
        [event.target.name]: event.target.value,
      });
    }
  };
  const uploadToS3 = async (e) => {
    let files = e.target.files;
    fileobj.push(files);
    let reader;
    for (var i = 0; i < fileobj[0].length; i++) {
      const imageFile = e.target.files[i];
      if (
        imageFile.name.match(/\.(jpg|jpeg|png|gif)$/) &&
        imageFile.size < 500000
      ) {
        await S3FileUpload.uploadFile(e.target.files[i], config)
          .then((data) => {
            ArticleImageUrl.push(data);
            setArticleImageUrl([...new Set(ArticleImageUrl)]);
          })
          .catch((err) => console.log(err));
        reader = new FileReader();
        reader.readAsDataURL(fileobj[0][i]);
        reader.onload = (event) => {
          preview.push(event.target.result); // update the array instead of replacing the entire value of preview
          setPreview([...new Set(preview)]); // spread into a new array to trigger rerender
        };
      } else {
        alert(`Please select valid format and Size`);
      }
    }
  };
  const onDelete = (index) => {
    setPreview((prev) => {
      let arr = prev;
      delete arr[index];
      const ImagesUrl = arr.filter((val) => val != null);
      setPreview(ImagesUrl);
    });
    setArticleImageUrl((data) => {
      let img = data;
      const s3 = new AWS.S3({
        accessKeyId: config.accessKeyId,
        secretAccessKey: config.secretAccessKey,
        region: "us-west-1",
      });
      let params = {
        Bucket: "ecomdailynews",
        Key: `${img[index]?.key}`,
      };
      s3.deleteObject(params, function (err, data) {
        if (err) console.log(err, err.stack);
        // error
        else {
          delete img[index];
          setArticleImageUrl(img);
          DELETEIMAGEPATCH({
            mediaUrl: ArticleImageUrl,
          });
        } // deleted
      });
      return; 
    });
  };
  const post = async () => {
    let ImagesUrl = ArticleImageUrl.filter((val) => val != null);
    await axios
      .put(`${process.env.REACT_APP_BACKEND}/events/${id.id}`, {
        eventTitle: Articles.eventTitle,
        eventDescription: Articles.eventDescription,
        eventContent: Articles.eventContent,
        startEvent: Articles.startEvent,
        endEvent: Articles.endEvent,
        mediaUrl: ImagesUrl.map((val) => (val.location ? val.location : val)),
        redirectUrl: Articles.redirectUrl,
      },{
        headers: {
          authtoken: `${localStorage.getItem("authtoken")}`,
        },
      })
      .then((res) => history.push("/Events"))
      .catch((err) => console.log(err));
  };
  const DELETEIMAGEPATCH = (obj) => {
    axios
      .patch(
        `${process.env.REACT_APP_BACKEND}/events/update-media-array/${id.id}`,
        obj
      )
      .then((data) => console.log(data))
      .catch((err) => console.log(err));
  };

  return (
    <div className="bodycolor">
      <div className="center" style={{ marginTop: "10px" }}>
        {Articles ? (
          <Card>
            <Row>
              <Col lg={1} sm={1} ></Col>
              <Col>
                <h3 className="heading">
                  <strong>Edit Event</strong>
                </h3>
                <div className="inputfields">
                  <div>
                    <label>Title :</label>
                  </div>
                  <input
                    className="inputvalues"
                    defaultValue={Articles?.eventTitle}
                    name="eventTitle"
                    value={Articles?.eventTitle}
                    onChange={onHandlechange}
                  ></input>
                </div>
                <div className="inputfields">
                  <div>
                    <label>Description :</label>
                  </div>
                  <input
                    onChange={onHandlechange}
                    name="eventDescription"
                    value={Articles?.eventDescription}
                    className="inputvalues"
                    placeholder="Enter The Description"
                  ></input>
                </div>
                <div className="inputfields">
                  <div>
                    <label>Content :</label>
                  </div>
                  <div
                    style={{
                      backgroundColor: "rgb(240 239 239)",
                      width: "530px",
                    }}
                  >
                    <CKEditor
                      data={Articles.eventContent}
                      editor={ClassicEditor}
                      onChange={onhandleChange}
                      onInit={(editor) => {}}
                      config={{
                        ckfinder: {
                          // ${process.env.REACT_APP_BACKEND}
                          uploadUrl: `${process.env.REACT_APP_BACKEND}/articles/upload`,
                        },
                      }}
                    />
                  </div>
                </div>
                <div className="inputfields">
                  <div>
                    <label>Media Url :</label>&nbsp;<span>Image  less than 500kb and extension ".jpg .jpeg .png"</span>
                  </div>
                  <input
                    onChange={uploadToS3}
                    type="file"
                    name="mediaUrl"
                    className="inputvalues"
                    placeholder="Enter The Url"
                    multiple
                  ></input>
                </div>
                <div style={{ marginLeft: "60px", marginTop: "20px" }}>
                  <Row>
                    {preview
                    
                      ? preview.map((url, key) => {
                          if (url != null) {
                            return (
                              <div>
                                <Container>
                                  <Col class="col-xs-6 col-md-10">
                                    <img
                                      src={url}
                                      alt="..."
                                      key={key}
                                      style={{
                                        height: "100px",
                                        width: "200px",
                                      }}
                                      onError={(event) =>
                                        (event.target.style.display = "none")
                                      }
                                    />
                                    <button
                                      type="button"
                                      onClick={() => onDelete(key)}
                                      class="close"
                                      aria-label="Close"
                                    >
                                      <span aria-hidden="true">&times;</span>
                                    </button>
                                  </Col>
                                  <div style={{ marginBottom: "10px" }}></div>
                                </Container>
                              </div>
                            );
                          }
                  
                        })
                      : null}
                  </Row>
                </div>
                <div className="inputfields">
                  <Row>
                    <Col lg={6} md={6} sm={6}>
                      <div>
                        <label>Start Event :</label>
                      </div>
                      <p>{moment(Articles?.startEvent).format("YYYY-MM-DD")}</p>
                    </Col>
                    <Col lg={6} md={6} sm={6}>
                      <div>
                        <label className="datelabel">End Event :</label>
                      </div>
                      <p style={{ marginLeft: "40px" }}>
                        {moment(Articles?.endEvent).format("YYYY-MM-DD")}
                      </p>
                    </Col>
                  </Row>
                </div>
                <div className="inputfields buttonadjust">
                  <button className="button" onClick={post}>
                    <strong>Save</strong>
                  </button>
                  <div className="backbutton">
                    {" "}
                    <Link to="/Events">
                      <button className="button">
                        <strong>
                          <span>
                            <i class="fas fa-arrow-left"></i>
                          </span>{" "}
                          &nbsp;Back
                        </strong>
                      </button>
                    </Link>
                  </div>
                </div>
              </Col>
            </Row>
          </Card>
        ) : null}
      </div>
    </div>
  );
};
export default SetArticles;
