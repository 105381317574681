import Routes from './routes/index';

function App() {
  return (
    <div>
      <Routes />
    </div>
  );
}

export default App;
