import React, { useState, useEffect } from "react";
import { Container, Alert } from "react-bootstrap";
import { useForm } from "react-hook-form";
import { useHistory, Link } from "react-router-dom";
import axios from "axios";
import * as Yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";
import { connect } from "react-redux";

const Login = (props) => {
  // const history = useHistory();

  // form validation rules
  const validationSchema = Yup.object().shape({
    email: Yup.string().required("Email is required").email("Email is invalid"),
    password: Yup.string().required("Password is required"),
  });

  const [loginStatus, setStatus] = useState(undefined);

  const [passwordShown, setPasswordShown] = useState(false);
  const { register, handleSubmit, errors } = useForm({
    resolver: yupResolver(validationSchema),
  });
  const handleKeyPress = (target) => {
    if (target.key === "Enter") {
      handleSubmit(onSubmit);
    }
  };
  const onSubmit = (data) => {
    
    axios
      .post(`${process.env.REACT_APP_BACKEND}/login`, data)
      .then((res) => {
        if (res.status === 200) {
          localStorage.setItem("authtoken", res.data);
          // history.push("/dashboard");
          // <Redirect to="/dashboard" />
          window.location.href = "/dashboard";
        } else {
          // <Redirect to="/login" />
          // history.push("/login");
          window.location.href = "/login";
        }
      })
      .catch((err) => console.log(err));
  };

  useEffect(() => {
    if (loginStatus !== undefined && loginStatus.status === "OK") {
      setSessionAndLocalStorage();
    }
  }, [loginStatus]);

  const renderLoginFailedMsg = () => {
    if (loginStatus !== undefined && loginStatus.status === "FAIL") {
      return (
        <div>
          <Alert variant="danger">{loginStatus.msg}</Alert>
        </div>
      );
    }
  };

  // Store accessToken and userDetails in localstorage
  const setSessionAndLocalStorage = () => {
    localStorage.setItem("accessToken", loginStatus.msg.accessToken);
    axios
      .get(`${process.env.REACT_APP_BACKEND}/api/staff/me`, {
        headers: {
          staffAccessToken: loginStatus.msg.accessToken,
        },
      })
      .then((fetchedData) => {
        let scrapedData = fetchedData.data.data;
        localStorage.setItem(
          "userData",
          JSON.stringify({
            email: scrapedData.email,
            id: scrapedData.id,
            staff_role_id: scrapedData.staff_role_id,
            system_operating_country_id:
              scrapedData.system_operating_country_id,
          })
        );

        localStorage.setItem("isLoggedIn", JSON.stringify(true));

        props.updateUserDetailsAndAccess({
          isLoggedIn: true,
          accessToken: loginStatus.msg.accessToken,
          userDetails: {
            email: scrapedData.email,
            id: scrapedData.id,
            staff_role_id: scrapedData.staff_role_id,
            system_operating_country_id:
              scrapedData.system_operating_country_id,
          },
        });
      });
  };

  return (
    <Container style={{ width: "100%" }}>
      <div
        style={{ paddingLeft: "20%", paddingRight: "20%", marginTop: "30px" }}
      >
        <form onSubmit={handleSubmit(onSubmit)}>
          <h1 style={{ textAlign: "center" }}>Sign In</h1>

          <div className="form-group col">
            <label>
              Email <span style={{ color: "red" }}>*</span>
            </label>
            <input
              placeholder="Enter email"
              name="email"
              type="text"
              ref={register}
              className={`form-control ${errors.email ? "is-invalid" : ""}`}
            />
            <div className="invalid-feedback">{errors.email?.message}</div>
          </div>
          <div className="form-group col">
            {/* border: '1px solid #ced4da', borderRadius: '5px' */}
            <label>
              Password <span style={{ color: "red" }}>*</span>
            </label>
            <div>
              <input
                style={{ width: "100%", display: "inline-block" }}
                name="password"
                type={passwordShown ? `text` : `password`}
                ref={register}
                className={`form-control ${
                  errors.password ? "is-invalid" : ""
                }`}
                placeholder="Enter password"
              />
              {/* <i 
                  onClick={ togglePasswordVisiblity } 
                  className={(passwordShown) ? `fa fa-eye-slash` : `fa fa-eye`} 
                  style={{ display: 'inline-block', width: '10%', border: '1px solid #ced4da', borderTopLeftRadius: 0, borderBottomLeftRadius: 0, borderLeft: 'none', paddingTop: '9px', paddingBottom: '11px', borderRadius: '5px', textAlign: 'center', marginLeft: 0 }}
                ></i> */}
              <div className="invalid-feedback" style={{ marginTop: "10px" }}>
                {errors.password?.message}
              </div>
            </div>
          </div>
          <div className="form-group col" style={{ textAlign: "end" }}>
            <button
              type="submit"
              style={{ width: "50%", display: "inline-block" }}
              className="btn btn-secondary"
              onKeyPress={(e) => handleKeyPress(e)}
            >
              {" "}
              Submit
            </button>
            <Link
              to="/signup"
              style={{ width: "50%", display: "inline-block" }}
            >
              Not registerd yet?
            </Link>
          </div>
        </form>

        {renderLoginFailedMsg()}
      </div>
    </Container>
  );
};

export default Login;
