import "./NewVideo.css";
import axios from "axios";
import { Button, Card, Row, Col } from "react-bootstrap";
import React, { Fragment, useState } from "react";
import { CKEditor } from "@ckeditor/ckeditor5-react";
import { useHistory, Link } from "react-router-dom";
import ClassicEditor from "@ckeditor/ckeditor5-build-classic";

const SetArticles = () => {
  const history = useHistory();
  const [Articles, setArticles] = useState();

  const onhandleChange = (event, editor) => {
    const data = editor.getData();
    setArticles({
      ...Articles,
      description: data,
    });
  };

  const onHandlechange = (event) => {
    event.preventDefault();
    setArticles({
      ...Articles,
      [event.target.name]: event.target.value,
    });
  };

  const post = () => {
    axios
      .post(`${process.env.REACT_APP_BACKEND}/videos`, Articles,{
        headers: {
          authtoken: `${localStorage.getItem("authtoken")}`,
        },
      })
      .then((res) => history.push("/video"))
      .catch((err) => console.log(err));
  };

  // const { quill, quillRef } = useQuill();
  return (
    <div className="bodycolor">
      <div className="center" style={{ marginTop: "10px" }}>
        <Card>
          <Row>
            <Col lg={1} sm={1} sm={1}></Col>
            <Col>
              <h3 className="heading">
                <strong>Create New Video</strong>
              </h3>
              <div className="inputfields">
                <div>
                  <label>Title :</label>
                </div>
                <input
                  className="inputvalues"
                  name="title"
                  onChange={onHandlechange}
                  placeholder="Enter The name"
                ></input>
              </div>
              <div className="inputfields">
                <div>
                  <label>Description :</label>
                </div>
                <CKEditor
                  data={Articles?.description}
                  editor={ClassicEditor}
                  onChange={onhandleChange}
                  onInit={(editor) => {}}
                  config={{
                    ckfinder: {
                      // ${process.env.REACT_APP_BACKEND}
                      uploadUrl: `${process.env.REACT_APP_BACKEND}/articles/upload`,
                    },
                  }}
                />
              </div>
              <div className="inputfields">
                <div>
                  <label>Media URL:</label>
                </div>
                <input
                  onChange={onHandlechange}
                  name="mediaUrl"
                  className="inputvalues"
                  placeholder="Enter The VideoUrl"
                ></input>
              </div>
              <div className="inputfields">
                <Row>
                  <Col lg={6} md={6} sm={6}>
                    <div>
                      <label>Type :</label>
                    </div>
                    <input
                      onChange={onHandlechange}
                      name="type"
                      className="datevalues"
                      placeholder="Enter The name"
                    ></input>
                  </Col>
                  <Col lg={6} md={6} sm={6}>
                    <div>
                      <label>Created On :</label>
                    </div>
                    <input
                      name="createdon"
                      className="datevalues"
                      type="date"
                      placeholder="Enter The name"
                    ></input>
                  </Col>
                </Row>
              </div>
              <div className="inputfields buttonadjust">
                <button className="button" onClick={post}>
                  <strong>Save</strong>
                </button>
                <div className="backbutton">
                  {" "}
                  <Link to="/video">
                    <button className="button">
                      <strong>
                        <span>
                          <i class="fas fa-arrow-left"></i>
                        </span>{" "}
                        &nbsp;Back
                      </strong>
                    </button>
                  </Link>
                </div>
              </div>
            </Col>
          </Row>
        </Card>
      </div>
    </div>
  );
};
export default SetArticles;
